<template>
  <template v-if="!allowedSize">
    <div class="mobile-notification-wrap">
      <div class="mobile-notification">
        <div class="notification-image">
          <img src="@/assets/images/mobile-notification.svg"
               width="181"
               height="133"
               alt="Mobile notification image"/>
        </div>
        <div class="notification-text">
          Unfortunately, this tool is only available for the desktop and tablet versions 😢
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="impressions-report rank-report">
      <div class="new-container">
        <div class="header">
          <h1 class="page-title mobile-hidden"
              :data-intercom="isGP ? 'GPImpressions' : 'ASImpressions'">
            {{ $route.meta.pageTitle }}
          </h1>
          <a v-if="isFreeUser"
             href="#"
             @click="openPlansModal"
             class="download-button">
            <svg-icon icon="download"/>
            Download
          </a>
          <a v-else
             :href="downloadUrl"
             class="download-button"
             download>
            <svg-icon icon="download"/>
            Download
          </a>
        </div>
        <div class="search-input-wrap">
          <live-search-with-presets :selected-app="selectedApp"
                                    v-if="selectedApp.store !== undefined"
                                    :current-country="currentCountry"
                                    :presets="myApps"
                                    :store="selectedApp.store?.key"
                                    @changed="searchResultsAppClicked"
          />

          <country-selector ref="countrySelector"
                            v-if="countries.length > 0"
                            :all-countries="countries"
                            :selected-outer="selectedCountries"
                            @changed="changeCountries"/>
          <default-spinner v-else/>
        </div>

        <div class="app-date-block">
          <div class="selected-app" v-if="selectedApp.info">
          <span class="logo">
            <app-image
              :width="36"
              :height="36"
              :src="selectedApp.info?.logo"
            />
          </span>
            <div class="selected-title">
              <a :href="selectedApp.info?.store_link" target="_blank">
                <svg-icon v-once icon="link-icon" class="link-icon common-app-store-link"/>
              </a>
              <span>{{ selectedApp.info?.title }}</span>
            </div>
            <img v-if="isGP"
                 src="@/assets/images/icons/google_play.png"
                 alt="Store icon"
                 class="store-icon"
                 width="22"
                 height="22">
            <img v-else
                 src="@/assets/images/icons/app_store.png"
                 alt="Store icon"
                 class="store-icon"
                 width="22"
                 height="22">

          </div>
          <datepicker-with-range-old
            v-if="dateFilter"
            :to-right="true"
            :date-filter="dateFilter"
            :min-date="minDate"
            :max-date="maxDate"
            :columns="2"
            :ranges="[7, 30, 90, 180]"
            @date-filter-changed="applyDatePicker"/>
        </div>

        <div class="filters-block">
          <filters-new :filters="filterConfigs"
                       ref="filters"
                       @apply-filters="filtersApplied"/>

          <div class="right-side">
            <div class="mode-switcher">
              <div :class="{active: mode === 'keywords'}" @click="changeMode('keywords')">
                By Keywords
              </div>
              <div :class="{active: mode === 'countries'}" @click="changeMode('countries')">
                By Countries
              </div>
            </div>

            <custom-dropdown :min-width="'182px'" classes="source-dropdown" :close-dropdown="closeConfigDropdown">
              <template v-slot:header>
                <span>{{ source === 'ranking' ? 'Ranking keywords' : 'Tracking keywords'}}</span>
              </template>
              <template v-slot:content>
                <div class="select-options">
                  <div class="option" @click="changeSource('ranking')">
                    <div class="circle" :class="{active: source === 'ranking'}"></div><div>Ranking keywords</div>
                  </div>
                  <div class="option"
                       v-if="isTrackingSourceEnabled"
                       @click="changeSource('tracking')">
                    <div class="circle" :class="{active: source === 'tracking'}"></div><div>Tracking keywords</div>
                  </div>
                  <custom-tooltip v-else
                                  width="200px"
                                  direction-vertical="bottom">
                    <template v-slot:slotTrigger>
                      <div class="option disabled">
                        <div class="circle"></div><div>Tracking keywords</div>
                      </div>
                    </template>
                    <template v-slot:slotContent v-if="!isTrackingSourceEnabled">
                      Tracked keywords are available only for your apps
                    </template>
                  </custom-tooltip>
                </div>
              </template>
            </custom-dropdown>

            <custom-dropdown :min-width="'100px'" classes="group-dropdown" :close-dropdown="closeConfigDropdown">
              <template v-slot:header>
                <span>{{ group === 'day' ? 'Day' : (group === 'week' ? 'Week' : 'Month')}}</span>
              </template>
              <template v-slot:content>
                <div class="select-options">
                  <div class="option" @click="changeGroup('day')">
                    <div class="circle" :class="{active: group === 'day'}"></div><div>Day</div>
                  </div>
                  <div class="option" @click="changeGroup('week')">
                    <div class="circle" :class="{active: group === 'week'}"></div><div>Week</div>
                  </div>
                  <div class="option" @click="changeGroup('month')">
                    <div class="circle" :class="{active: group === 'month'}"></div><div>Month</div>
                  </div>
                </div>
              </template>
            </custom-dropdown>

            <custom-dropdown classes="show-data-dropdown" :close-dropdown="closeConfigDropdown">
              <template v-slot:header>
                <div>
                  <svg-icon icon="setting"/>
                </div>
              </template>
              <template v-slot:content>
                <div class="select-options">
                  <div class="option" @click="changeShowChanges('all')">
                    <div class="option-text">
                      <div class="circle" :class="{active: showChanges === 'all'}"></div>
                      <div>Show all changes</div>
                    </div>
                  </div>
                  <div class="ignore-title">
                    <div>Ignore changes in Report:</div>
                  </div>
                  <div class="option" @click="changeShowChanges('ignore_popularity')">
                    <div class="option-text">
                      <div class="circle" :class="{active: showChanges === 'ignore_popularity'}"></div>
                      <div>Ignore Keyword Popularity changes</div>
                    </div>
                    <custom-tooltip width="400px"
                                    direction-vertical="bottom"
                                    direction-horizontal="left">
                      <template v-slot:slotTrigger>
                        <svg-icon icon="question-circle-icon"
                                  class="question-tooltip"/>
                      </template>
                      <template v-slot:slotContent>
                        For this period, maintain the initial <b>popularity of the keywords as a constant</b> (fixing first found keyword popularity).
                        Assume it remains unchanged. <b>Estimated visibility changes will consider shifts in app positions.</b>
                      </template>
                    </custom-tooltip>
                  </div>
                  <div class="option" @click="changeShowChanges('ignore_rank')">
                    <div class="option-text">
                      <div class="circle" :class="{active: showChanges === 'ignore_rank'}"></div>
                      <div>Ignore App Rank changes</div>
                    </div>
                    <custom-tooltip width="400px"
                                    direction-vertical="bottom"
                                    direction-horizontal="left">
                      <template v-slot:slotTrigger>
                        <svg-icon icon="question-circle-icon"
                                  class="question-tooltip"/>
                      </template>
                      <template v-slot:slotContent>
                        For this period, maintain the initial <b>app rank as constant</b> (fixing first found position).
                        Assume it remains unchanged. <b>Estimated visibility changes will solely consider shifts in keyword popularity.</b>
                      </template>
                    </custom-tooltip>
                  </div>
                </div>
              </template>
            </custom-dropdown>
          </div>
        </div>

        <div class="content">
          <template v-if="dataIsLoading">
            <progress-indicator>Data is loading...</progress-indicator>
          </template>
          <server-error v-else-if="showTimeoutError"
                        button-text="Contact us for help"
                        @clicked="openIntercom">
            <template v-slot:title>
              Data Overflow…
            </template>
            <template v-slot:text>
              <p>
                Due to the large volume of data received for this application, generating the report caused an error.
              </p>
              <p>
                <b>
                  Please decrease the time period
                  <span v-if="selectedCountries.length > 1 || selectedCountries.length === 0">
                  or reduce the number of countries
                </span>
                </b>
                to generate a report for this application.
              </p>
              <p>
                <i>We are working to prevent this from happening in the future.</i>
              </p>
            </template>
          </server-error>
          <template v-else>
            <div v-if="noDataTitle" class="no-data-block">
              <span>
                <span class="logo">
                  <app-image
                    :width="28"
                    :height="28"
                    :src="selectedApp.info?.logo"
                  />
                </span>
                <span class="app-title">{{ selectedApp.info?.title }}</span>
                {{ noDataTitle }}
              </span>
            </div>
            <template v-else>
              <impressions-chart :chart-data="chartData"
                                 v-if="chartData.length > 0"
                                 :title="chartTitle"
              />

              <impressions-table :store-key="this.selectedApp.store?.key"
                                 :mode="mode"
                                 :data-provider="dataProvider"
                                 :is-simplified="isTableDataSimplified"
                                 @refresh-data="refreshData"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import SearchAppInput from "@/components/UI/SearchAppInput/index.vue";
import {mapGetters} from "vuex";
import DatepickerWithRange from "@/components/UI/DatepickerWithRange/index.vue";
import myApps from "@/api/modules/my-apps";
import {httpRequest} from "@/api";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import ImpressionsTable from "@/views/Reports/Impressions/components/ImpressionsTable/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown";
import CountrySelector from "@/views/Reports/components/CountrySelector/index.vue";
import {formatDate, reverseDayMonthDate} from "@/utils/utils";
import Preloader from "@/components/UI/Preloader/index.vue";
import DefaultSpinner from "@/components/UI/DefaultSpinner/index.vue";
import {GenerateQueryUrl} from "@/utils/factories";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import {getDefaultMinDate} from "@/utils/utils";
import FiltersNew from "@/components/UI/FiltersComponent/FiltersNew.vue";
import {SearchFilterData, PaginationData, SortingData} from "@/api/modules/tables/search-filter-data";
import {ApiDataProvider} from "@/api/modules/tables/api-data-provider";
import ImpressionsChart from "@/views/Reports/Impressions/components/ImpressionsChart/index.vue";
import axios from "axios";
import DatepickerWithRangeOld from "@/components/UI/DatepickerWithRangeOld/index.vue";
import ServerError from "@/components/ServerError/index.vue";
import LiveSearchWithPresets from "@/components/Dropdowns/LiveSearchWithPresets/index.vue";

export default {
  name: 'ImpressionsReport',
  components: {
    LiveSearchWithPresets,
    ServerError,
    DatepickerWithRangeOld,
    ImpressionsChart,
    FiltersNew,
    AppImage,
    DefaultSpinner,
    Preloader,
    CountrySelector,
    CustomDropdown,
    ImpressionsTable,
    SvgIcon,
    ProgressIndicator,
    DatepickerWithRange,
    SearchAppInput,
  },
  data() {
    return {
      dateFilter: null,
      minDate: new Date(),
      maxDate: new Date(),
      selectedApp: {},
      chartData: [],
      tableData: [],
      dataIsLoading: true,
      source: 'ranking',
      mode: 'keywords',
      group: 'day',
      showChanges: 'all',
      countries: [],
      selectedCountries: null,
      myApps: [],
      liveSearchApps: [],
      appSearchLoaded: false,
      appliedFilters: {},
      dataProvider: new ApiDataProvider(new SearchFilterData(
        new PaginationData(),
        new SortingData('impression', 'desc', 'impression'),
      )),
      closeConfigDropdown: 0,
      cancelToken: axios.CancelToken.source(),
      countriesCancelToken: axios.CancelToken.source(),
      unfilteredTotal: 0,
      trekkingKeywordsTotal: 0,
      isTableDataSimplified: false,
      showTimeoutError: false,
    };
  },
  created() {
    this.minDate = getDefaultMinDate();
    this.maxDate.setDate((new Date()).getDate() - 1);
    this.setDefaultDates();

    this.setParamsFromUrl();
  },
  async mounted() {
    if (this.selectedCountries === null) {
      this.selectedCountries = [];
      if (this.currentCountry) {
        this.selectedCountries = [this.currentCountry.code];
      }
    }
    if (this.currentApp?.id && this.selectedApp.id === undefined) {
      this.selectedApp = {...this.currentApp};
    }
    await this.fetchMyApps();
    await this.fetchCountries();
  },
  methods: {
    setParamsFromUrl() {
      let queryString = window.location.search;
      if (queryString !== '') {
        let urlParams = new URLSearchParams(queryString);
        this.dateFilter.start = new Date(reverseDayMonthDate(urlParams.get('date_from')));
        this.dateFilter.end = new Date(reverseDayMonthDate(urlParams.get('date_to')));
        this.group = urlParams.get('group_by');
        this.mode = urlParams.get('mode');
        this.source = urlParams.get('source');
        this.show_changes = urlParams.get('show_changes');
        this.selectedCountries = urlParams.getAll('country_codes[]');

        let urlParamsObject = Object.fromEntries(urlParams.entries());
        Object.keys(urlParamsObject).forEach((param) => {
          if (param.includes('filter')) {
            this.appliedFilters[param] = urlParamsObject[param];
          }
        });

        this.selectedApp = {
          id: urlParamsObject.app_id
        };
      }
    },
    openIntercom() {
      Intercom('showNewMessage');
    },
    setDefaultDates() {
      this.dateFilter = {...this.getLastDaysRange(30)};
    },
    changeCountries(codes) {
      if (codes.length !== 1 && !this.isFreeUser) {
        this.mode = 'countries';
      }
      this.selectedCountries = codes;
    },
    changeSource(source) {
      if (source === 'tracking') {
        if (!this.isTrackingSourceEnabled) {
          return;
        }
        if (this.isFreeUser) {
          this.openPlansModal();
          return;
        }
      }

      this.source = source;
    },
    changeMode(mode) {
      if (this.isFreeUser) {
        this.openPlansModal();
      } else {
        this.mode = mode;
      }
    },
    changeGroup(group) {
      if (this.isFreeUser && group !== 'day') {
        this.openPlansModal();
      } else {
        this.group = group;
      }
    },
    changeShowChanges(value) {
      this.showChanges = value;
    },
    applyDatePicker(event) {
      if (this.isFreeUser) {
        this.openPlansModal();
      } else {
        let tempFilterObj = {
          start: event?.start,
          end: event?.end,
        }
        this.dateFilter = {...tempFilterObj};
      }
    },
    getLastDaysRange(days) {
      const nowStart = new Date();
      const nowEnd = new Date();
      return {
        start: nowStart.setDate(nowStart.getDate() - days - 1),
        end: nowEnd.setDate(nowEnd.getDate() - 1),
      }
    },
    async fetchMyApps() {
      this.myApps = await myApps.getAllMyApps();
    },
    async fetchCountries() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.REPORT_COUNTRIES + this.countriesRequestUrl;
      let countries = await httpRequest('GET', url);
      if (this.countries.length === 0 && countries instanceof Array && countries.length > 0) {
        this.countries = countries;
      }
    },
    async fetchCountriesCounts() {
      if (this.selectedApp.id && this.source === 'tracking') {
        let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.REPORT_COUNTRIES
          + this.countriesRequestUrl + '&with_counts=1';
        this.countriesCancelToken.cancel();
        this.countriesCancelToken = axios.CancelToken.source();
        this.clearCountryCounts();
        let countries = await httpRequest('GET', url, null, {cancelToken: this.countriesCancelToken.token});
        if (countries instanceof Array && countries.length > 0) {
          this.countries = countries;
        }
      } else {
        this.clearCountryCounts();
      }
    },
    clearCountryCounts() {
      this.countries.forEach((group) => {
        group.countries.forEach((country) => {
          country.count = null;
        });
      });
    },
    async fetchChartData(onlyTableUpdate) {
      if (this.requestUrl !== null) {
        this.dataIsLoading = onlyTableUpdate !== true;
        this.closeConfigDropdown++;

        let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.IMPRESSIONS_REPORT + this.requestUrl
          + this.dataProvider.generateRequest(this.likeUrlParam);
        this.cancelToken.cancel();
        this.cancelToken = axios.CancelToken.source();
        let data = await httpRequest('GET', url, null, {cancelToken: this.cancelToken.token});

        if (data.list !== undefined) {
          if (onlyTableUpdate !== true) {
            this.chartData = data.chart_data;
          }
          let tableData = data.list;
          tableData.forEach((item) => {
            item.checked = true;
          });
          this.dataProvider.setItems(tableData, data.list_total);
          this.showTimeoutError = false;
          this.dataIsLoading = false;
          this.showTimeoutError = false;
          this.unfilteredTotal = data.unfiltered_total;
          this.trekkingKeywordsTotal = data.trekking_keywords_total;
          this.isTableDataSimplified = data.is_simplified;
        } else if (data.status === 500 || data.status === 502) {
          this.showTimeoutError = true;
          this.dataIsLoading = false;
        }
      }
    },
    async searchResultsAppClicked(item) {
      if (this.isFreeUser && !this.isMyApp(item.id)) {
        this.openPlansModal();
      } else {
        this.selectedApp = {...item};
      }
    },
    async filtersApplied(e) {
      if (this.isFreeUser) {
        this.openPlansModal();
      } else if(JSON.stringify(this.appliedFilters) !== JSON.stringify(e)) {
        this.appliedFilters = {...e};
      }
    },
    isMyApp(appId) {
      return this.myApps.some((item) => {
        return +item.id === +appId;
      });
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    refreshData(searchFilterData) {
      this.fetchChartData(true);
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'userSubscription',
    ]),
    countryForLiveSearch() {
      let countryCode = null;
      if (this.selectedCountries.length === 1) {
        countryCode = this.selectedCountries[0];
      } else if (this.selectedCountries.length > 1 && this.selectedCountries.includes(this.currentCountry?.code)) {
        countryCode = this.currentCountry.code;
      }

      let fullCountry = this.countries.find((country) => country.code === countryCode);

      return {
        code: countryCode,
        name: fullCountry?.name,
        id: fullCountry?.id,
      };
    },
    allowedSize() {
      return this.windowWidth >= 768;
    },
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    downloadUrl() {
      return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.EXPORTS_IMPRESSIONS_REPORT
        + this.requestUrl + this.dataProvider.generateCSVRequest(this.likeUrlParam);
    },
    likeUrlParam() {
      if (this.isTableDataSimplified) {
        return 'like';
      } else {
        return this.mode === 'keywords' ? 'like_keyword' : 'like_country';
      }
    },
    requestUrl() {
      if (this.selectedApp.id === undefined) {
        return null;
      }

      let url = '?app_id=' + this.selectedApp.id
        + '&date_from=' + formatDate(this.dateFilter.start, 'digits-dash')
        + '&date_to=' + formatDate(this.dateFilter.end, 'digits-dash')
        + '&group_by=' + this.group
        + '&mode=' + this.mode
        + '&source=' + this.source
        + '&show_changes=' + this.showChanges
        + GenerateQueryUrl(this.appliedFilters);

      this.selectedCountries.forEach((code) => {
        url += '&country_codes[]=' + code;
      });

      return url;
    },
    countriesRequestUrl() {
      if (this.selectedApp.id === undefined) {
        return null;
      }

      return '?app_id=' + this.selectedApp.id
        + '&date_from=' + formatDate(this.dateFilter.start, 'digits-dash')
        + '&date_to=' + formatDate(this.dateFilter.end, 'digits-dash')
        + '&source=' + this.source
        + '&show_changes=' + this.showChanges;
    },
    filterConfigs() {
      let filters = [
        {
          type: 'range',
          title: 'Est. Impression',
          filter: 'range_estimated_impression',
          minLimit: 0,
          selectedValue: {
            min: this.appliedFilters['filter[range_estimated_impression][from]'] ?? null,
            max: this.appliedFilters['filter[range_estimated_impression][to]'] ?? null,
          },
        },
        {
          type: 'range',
          title: 'Popularity',
          filter: 'range_sap',
          minLimit: 0,
          maxLimit: 100,
          selectedValue: {
            min: this.appliedFilters['filter[range_sap][from]'] ?? null,
            max: this.appliedFilters['filter[range_sap][to]'] ?? null,
          },
        },
        {
          type: 'range',
          title: 'Best rank',
          filter: 'range_rank',
          minLimit: 1,
          maxLimit: 10,
          selectedValue: {
            min: this.appliedFilters['filter[range_rank][from]'] ?? null,
            max: this.appliedFilters['filter[range_rank][to]'] ?? null,
          },
        },
      ];

      if (this.mode === 'countries') {
        filters.push({
          type: 'range',
          title: 'Total keywords',
          filter: 'range_keywords_count',
          minLimit: 1,
          selectedValue: {
            min: this.appliedFilters['filter[range_keywords_count][from]'] ?? null,
            max: this.appliedFilters['filter[range_keywords_count][to]'] ?? null,
          },
        });
      }

      return filters;
    },
    isTrackingSourceEnabled() {
      return this.selectedApp.id && this.isMyApp(this.selectedApp.id);
    },
    chartTitle() {
      if (this.showChanges === 'ignore_popularity') {
        return 'Report Data ignores Keyword Popularity changes';
      } else if (this.showChanges === 'ignore_rank') {
        return 'Report Data ignores Keyword Rank changes';
      } else {
        return '';
      }
    },
    noDataTitle() {
      if (this.unfilteredTotal > 0) {
        return null;
      }

      if (this.source === 'ranking') {
        return 'doesn’t have ranking keywords with estimated impression for selected countries.'
      } else {
        if (this.trekkingKeywordsTotal === 0) {
          return 'doesn’t have tracked keywords for selected countries.'
        } else {
          return 'doesn’t have tracked keywords with estimated impression for selected countries.'
        }
      }
    },
    isGP() {
      return this.selectedApp.store?.key === 'GOOGLE_PLAY';
    }
  },
  watch: {
    currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.selectedApp = this.currentApp;
      }
    },
    async selectedApp(newVal, oldVal) {
      if (newVal.id && (newVal.id !== oldVal.id || newVal.info === undefined)) {
        let appData = await myApps.getApp(this.selectedApp.id, this.currentCountry.code);
        this.selectedApp.info = appData.app_info;
        this.selectedApp.store = {
          key: appData.app_info.store
        };
      }
      if (!this.isMyApp(this.selectedApp) && this.source === 'tracking') {
        this.source = 'ranking';
      }
    },
    requestUrl() {
      this.dataProvider.resetPagination();
      this.fetchChartData();
      if (window.history.replaceState) {
        window.history.replaceState({url: this.requestUrl}, '', this.requestUrl);
      }
    },
    countriesRequestUrl() {
      this.fetchCountriesCounts();
    },
  },
}
</script>

<style src="./../styles.scss" lang="scss"></style>
<style src="./styles.scss" lang="scss"></style>
